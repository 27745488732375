//eslint-disable-next-line @typescript-eslint/no-explicit-any
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { useState, useRef } from 'react'
import {
  setDropDown,
  setEvalApiResponse,
  setOptApiResponse,
  setStartDate,
  setWaypoints,
  fetchEval,
  parseWaypoints,
  routeCastEdit,
  saveRouteApi,
  incrementRoute,
  setSelectedIndex,
  decrementRouteArray,
} from './routecastSlice'
import chevronDown from '../../../assets/chev-up.svg'

import { useMap } from 'react-leaflet'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import SavedRoutesMenu from './SavedRoutesMenu'
import RouteParams from '../../../interfaces/RouteCast'

const initRouteState: RouteParams = {
  selectedDate: new Date(),
  speedVal: 10,
  yellow1: 5,
  red1: 10,
  yellow2: 5,
  red2: 10,
  windSpeedInputs: {
    yellow: 9,
    red: 15,
  },
  eightyMWindInputs: {
    yellow: 10,
    red: 15,
  },
  ceilingInputs: { yellow: 1000, red: 600 },
  visibilityInputs: {
    yellow: 4,
    red: 3,
  },
  selectedOpValue: 'WIND_RISK',
  sliderValue: 0,
  selectedParameter: 'winds',
  routeName: '',
}

const RouteCAST = () => {
  const dispatch = useDispatch<any>()
  const [routeArray, setRouteArray] = useState<Array<RouteParams>>([
    initRouteState,
  ])
  const selectedIndex = useSelector((state) => state.rcast.selectedIndex)
  // const [selectedDate, setSelectedDate] = useState(new Date())
  // const [speedVal, setSpeedVal] = useState(10)
  // const [yellow1, changeYellow1] = useState(5)
  // const [red1, changeRed1] = useState(10)
  // const [yellow2, changeYellow2] = useState(5)
  // const [red2, changeRed2] = useState(10)
  // const [windSpeedInputs, setWindSpeedInputs] = useState({ yellow: 9, red: 15 })
  // const [eightyMWindInputs, setEightyMWindInputs] = useState({
  //   yellow: 10,
  //   red: 15,
  // })
  // const [ceilingInputs, setCeilingInputs] = useState({ yellow: 1000, red: 600 })
  // const [visibilityInputs, setVisibilityInputs] = useState({
  //   yellow: 4,
  //   red: 3,
  // })
  // const [selectedOpValue, setSelectedOpValue] = useState('WIND_RISK')
  // const [sliderValue, setSliderValue] = useState(0)
  // const [selectedParameter, setSelectedParameter] = useState('winds')
  const waypoints = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].waypoints
  )
  const showRC = useSelector((state: RootState) => state.rcast.showRouteCAST)
  const [red1InputError, setRed1InputError] = useState(false)
  const [red2InputError, setRed2InputError] = useState(false)
  const [yellow1InputError, setYellow1InputError] = useState(false)
  const [yellow2InputError, setYellow2InputError] = useState(false)
  const [evalError, setEvalError] = useState(null)
  const [optError, setOptError] = useState(null)

  const [showComponent, setShowComponent] = useState(false)
  const [yellow1Error, setYellow1Error] = useState('')
  const [red1Error, setRed1Error] = useState('')
  const [yellow2Error, setYellow2Error] = useState('')
  const [red2Error, setRed2Error] = useState('')
  const [speedError, setSpeedError] = useState(false)
  const [flightSpeedError, setflightSpeedError] = useState('')
  const [waypointsError, setWaypointsError] = useState(false)
  const [routeLabelError, setRouteLabelError] = useState<boolean>(false)
  const [dateError, setDateError] = useState('')
  const dropDown = useSelector((state: RootState) => state.rcast.dropDown)
  const [showTooltip, setShowTooltip] = useState(false)
  const [showSaveMenu, setShowSaveMenu] = useState<boolean>(false)
  const drawnPolylineRef = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].drawnPath
  )
  const leafletMap = useMap()
  const errorStatus = useErrorStatus()
  const [disableRouteDeleteBtn, setDisableRouteDeleteBtn] =
    useState<boolean>(true)
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false)

  let red1Class //= 'border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2'
  let red2Class //= 'border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2'
  let yellow1Class //= 'border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2'
  let yellow2Class //= 'border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2'
  let flightSpeedClass //= 'border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2'
  const evalApiResponse = useSelector(
    (state) => state.rcast.routes[selectedIndex].evalApiResponse
  )

  useEffect(() => {
    if (waypoints) {
      setShowComponent(true)
    }
    if (waypoints.length > 0) {
      setWaypointsError(false)
    } else {
      setWaypointsError(true)
    }
  }, [waypoints])

  useEffect(() => {
    if (evalError !== null) {
      errorStatus.addMessage(evalError, 400, 'error', true)
    }
    if (optError !== null) {
      errorStatus.addMessage(optError, 400, 'error', true)
    }
  }, [evalError, optError])

  useEffect(() => {
    if (red1InputError === true) {
      red1Class = `border-red-500 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    } else {
      red1Class = `border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    }
  }, [red1InputError])

  useEffect(() => {
    if (red2InputError === true) {
      red2Class = `border-red-500 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    } else {
      red2Class = `border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    }
  }, [red2InputError])

  useEffect(() => {
    if (yellow1InputError === true) {
      yellow1Class = `border-red-500 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    } else {
      yellow1Class = `border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    }
  }, [yellow1InputError])

  useEffect(() => {
    if (yellow2InputError === true) {
      yellow2Class = `border-red-500 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    } else {
      yellow2Class = `border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    }
  }, [yellow2InputError])

  useEffect(() => {
    if (speedError === true) {
      flightSpeedClass = `border-red-500 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    } else {
      flightSpeedClass = `border-gray-300 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`
    }
  }, [speedError])

  const handleTooltipHover = () => {
    setShowTooltip(true)
  }

  const handleTooltipLeave = () => {
    setShowTooltip(false)
  }

  const handleChange = (event: any) => {
    const route = routeArray[selectedIndex]
    route.selectedParameter = event.target.value
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
  }
  const handleOpChange = (e: any) => {
    const route = routeArray[selectedIndex]
    route.selectedOpValue = e.target.value
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
  }
  const handleSliderChange = (event: any) => {
    const route = routeArray[selectedIndex]
    const newValue = parseInt(event.target.value, 10)
    route.sliderValue = newValue
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
  }
  const handleDateChange = (date: Date) => {
    const route = routeArray[selectedIndex]
    if (date < new Date()) {
      setDateError('Date and time must not be in the past')
    } else {
      setDateError('')
    }

    route.selectedDate = date
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
  }

  const handleSpeedChange = (speed: any) => {
    const route = routeArray[selectedIndex]
    const value = Number(speed.target.value)
    if (value < 0 || value > 50) {
      setflightSpeedError('Value must be between 0 and 50')
    } else {
      setflightSpeedError('')
    }
    route.speedVal = value
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
  }
  const handleYellow1Change = (tlow: any) => {
    const route = routeArray[selectedIndex]
    const value = Number(tlow.target.value)
    let error = ''
    // changeYellow1(Number(tlow.target.value))
    if (route.selectedParameter === 'winds' && (value < 0 || value > 100)) {
      error = 'Value must be between 0 and 100'
    }
    if (
      route.selectedParameter === 'ceilingVisibility' &&
      (value < 0 || value > 10000)
    ) {
      error = 'Value must be between 0 and 10000'
    }
    route.selectedParameter === 'winds'
      ? (route.windSpeedInputs.yellow = value)
      : (route.ceilingInputs.yellow = value)
    setYellow1Error(error)
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
    // errorStatus.addMessage(error,400,'error',true)
  }
  const handleRed1Change = (thigh: any) => {
    const route = routeArray[selectedIndex]
    const value = Number(thigh.target.value)
    let error = ''
    // changeRed1(Number(thigh.target.value))
    setRed1InputError(false)
    if (route.selectedParameter === 'winds' && (value < 0 || value > 100)) {
      error = 'Value must be between 0 and 100'
      setRed1InputError(true)
      red1Class =
        'border-red-500 text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2'
    }
    if (
      route.selectedParameter === 'ceilingVisibility' &&
      (value < 0 || value > 10000)
    ) {
      error = 'Value must be between 0 and 10000'
      setRed1InputError(true)
    }
    route.selectedParameter === 'winds'
      ? (route.windSpeedInputs.red = thigh.target.value)
      : (route.ceilingInputs.red = thigh.target.value)
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
    setRed1Error(error)
  }
  //-----------------------------------------
  //-----------------------------------------
  //-----------------------------------------

  const handleYellow2Change = (alow: any) => {
    const route = routeArray[selectedIndex]
    const value = Number(alow.target.value)
    let error = ''

    if (route.selectedParameter === 'winds' && (value < 0 || value > 100)) {
      error = 'Value must be between 0 and 100'
    }
    if (
      route.selectedParameter === 'ceilingVisibility' &&
      (value < 0 || value > 50)
    ) {
      error = 'Value must be between 0 and 50'
    }
    route.selectedParameter === 'winds'
      ? (route.eightyMWindInputs.yellow = alow.target.value)
      : (route.visibilityInputs.yellow = alow.target.value)
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
    setYellow2Error(error)
  }

  const handleRed2Change = (ahigh: any) => {
    const route = routeArray[selectedIndex]
    const value = Number(ahigh.target.value)
    let error = ''

    // changeRed2(Number(ahigh.target.value))
    if (route.selectedParameter === 'winds' && (value < 0 || value > 100)) {
      error = 'Value must be between 0 and 100'
    }
    if (
      route.selectedParameter === 'ceilingVisibility' &&
      (value < 0 || value > 50)
    ) {
      error = 'Value must be between 0 and 50'
    }
    route.selectedParameter === 'winds'
      ? (route.eightyMWindInputs.red = ahigh.target.value)
      : (route.visibilityInputs.red = ahigh.target.value)
    setRed2Error(error)
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      route,
      ...routeArray.slice(selectedIndex + 1, routeArray.length),
    ])
  }
  const handleYellow1Blur = (event: any) => {
    if (yellow1Error !== '') {
      errorStatus.addMessage(yellow1Error, 400, 'error', true)
    }
  }
  const handleRed1Blur = (event: any) => {
    if (red1Error !== '') {
      errorStatus.addMessage(red1Error, 400, 'error', true)
    }
  }
  const handleYellow2Blur = (event: any) => {
    if (yellow2Error !== '') {
      errorStatus.addMessage(yellow2Error, 400, 'error', true)
    }
  }
  const handleRed2Blur = (event: any) => {
    if (red2Error !== '') {
      errorStatus.addMessage(red2Error, 400, 'error', true)
    }
  }

  const handleButtonClick = () => {
    setShowComponent(!showComponent)
    setTimeout(() => {
      dispatch(setDropDown(false))
    }, 500)
  }
  const calculatePathCenter = (
    waypoints: [number, number][]
  ): [number, number] => {
    let totalLat = 0
    let totalLng = 0

    waypoints.forEach((coord) => {
      totalLat += coord[0]
      totalLng += coord[1]
    })

    const avgLat = totalLat / waypoints.length
    const avgLng = totalLng / waypoints.length

    return [avgLat, avgLng]
  }
  const handleClearWaypoints = () => {
    if (drawnPolylineRef) {
      drawnPolylineRef.remove()
      drawnPolylineRef.current = null
    }
    dispatch(setWaypoints(''))
    dispatch(setEvalApiResponse(null))
    dispatch(setOptApiResponse(null))
    setShowComponent(false)
    leafletMap.dragging.enable()
  }
  const handleEvaluateClick = async () => {
    dispatch(routeCastEdit(false))
    setEvalError(null)
    if (waypoints === '') {
      setWaypointsError(true)
      errorStatus.addMessage(
        'Please use the line or poly tool in the corner of the map to select your waypoints.',
        200,
        'error',
        false
      )
      return
    }
    if (
      !yellow1Error &&
      !red1Error &&
      !yellow2Error &&
      !red2Error &&
      !flightSpeedError &&
      !dateError &&
      !waypointsError
    ) {
      try {
        const coordinates = waypoints
          .split(',')
          .map((coord) => parseFloat(coord))

        const waypointPairs = []
        for (let i = 0; i < coordinates.length; i += 2) {
          const lat = coordinates[i]
          const lng = coordinates[i + 1]
          waypointPairs.push([lat, lng])
        }
        const pathCenter = calculatePathCenter(waypointPairs)
        const waypointsWithAltitude = parseWaypoints(waypoints)
        let requestBody
        const route = routeArray[selectedIndex]
        if (route.selectedParameter === 'winds') {
          requestBody = {
            startTime: route.selectedDate.getTime(),
            speedKts: route.speedVal,
            waypoints: waypointsWithAltitude,
            yellowWindSpeedTakeoffLandingKts: route.yellow1, // get these values from form instead
            redWindSpeedTakeoffLandingKts: route.red1,
            yellowWindSpeedAloftKts: route.yellow2,
            redWindSpeedAloftKts: route.red2,
            format: 'GEO_JSON',
          }
        } else {
          requestBody = {
            startTime: route.selectedDate.getTime(),
            speedKts: route.speedVal,
            waypoints: waypointsWithAltitude,
            yellowCeilingFeet: route.yellow1,
            redCeilingFeet: route.red1,
            yellowVisibilityMiles: route.yellow2,
            redVisibilityMiles: route.red2,
            format: 'GEO_JSON',
          }
        }

        dispatch(setStartDate(route.selectedDate.toISOString()))
        const responseAction = await dispatch(fetchEval(requestBody))

        if (fetchEval.fulfilled.match(responseAction)) {
          const responseData = responseAction.payload
          // dispatch(setEvalApiResponse(responseData))
          setShowComponent(!showComponent)
        } else if (fetchEval.rejected.match(responseAction)) {
          let msg = responseAction.payload.message
          if (responseAction.payload.name === 'AbortError') {
            msg = 'RouteCAST request timeout. Please try again later.'
            setEvalError(msg) // error is queued in useEffect
          }
        } else {
          errorStatus.addMessage('Error fetching data.', 400, 'error', false)
        }
        leafletMap.dragging.enable()
      } catch (error) {
        errorStatus.addMessage('Error: ' + error, 400, 'error', false)
      }
    } else {
      errorStatus.addMessage(
        'Please correct the highlighted input errors and try again.',
        400,
        'error',
        true
      )
    }
  }

  const addNewRoute = () => {
    setRouteArray([...routeArray, initRouteState])
    dispatch(incrementRoute())
  }

  const deleteCurrentRoute = () => {
    if (routeArray.length > 1) {
      dispatch(decrementRouteArray(selectedIndex))
      // there are only going to be 3 routes, this code doesn't have to be fancy.
      //if they ever decide to increase the routes, I will refactor this
      if (routeArray.length === 3) {
        if (selectedIndex === 0) {
          setRouteArray((prev) => [prev[1], prev[2]])
        } else if (selectedIndex == 1) {
          setRouteArray((prev) => [prev[0], prev[2]])
        } else {
          setRouteArray((prev) => [prev[0], prev[1]])
        }
      } else if (routeArray.length === 2) {
        if (selectedIndex === 0) {
          setRouteArray((prev) => [prev[1]])
        } else {
          setRouteArray((prev) => [prev[0]])
        }
      }
      if (selectedIndex > 0) {
        dispatch(setSelectedIndex(selectedIndex - 1))
      }
    } else {
      errorStatus.addMessage(
        'You need to have atleast one route to use RouteCAST.',
        400,
        'error',
        true
      )
    }
  }
  // const handleOptimizeClick = async () => {
  //   if (
  //     !yellow1Error &&
  //     !red1Error &&
  //     !yellow2Error &&
  //     !red2Error &&
  //     !flightSpeedError &&
  //     !dateError
  //   ) {
  //     try {
  //       const waypointsWithAltitude = parseWaypoints(waypoints)
  //       let reqBody
  //       if (selectedParameter === 'winds') {
  //         reqBody = {
  //           cost: selectedOpValue,
  //           startTime: selectedDate.getTime(),
  //           speedKts: speedVal,
  //           waypoints: waypointsWithAltitude,
  //           yellowWindSpeedTakeoffLandingKts: yellow1,
  //           redWindSpeedTakeoffLandingKts: red1,
  //           yellowWindSpeedAloftKts: yellow2,
  //           redWindSpeedAloftKts: red2,
  //           format: 'GEO_JSON',
  //           windAversionFactor: sliderValue,
  //         }
  //       } else {
  //         reqBody = {
  //           cost: selectedOpValue,
  //           startTime: selectedDate.getTime(),
  //           speedKts: speedVal,
  //           waypoints: waypointsWithAltitude,
  //           yellowCeilingFeet: yellow1,
  //           redCeilingFeet: red1,
  //           yellowVisibilityMiles: yellow2,
  //           redVisibilityMiles: red2,
  //           format: 'GEO_JSON',
  //           windAversionFactor: sliderValue,
  //         }
  //       }

  //       const responseAction = await dispatch(fetchOpt(reqBody))

  //       if (fetchOpt.fulfilled.match(responseAction)) {
  //         const responseData = responseAction.payload
  //         dispatch(clearEvalApiResponse())
  //         dispatch(setOptApiResponse(responseData))
  //       } else if (fetchOpt.rejected.match(responseAction)) {
  //         errorStatus.addMessage(
  //           'Error fetching data:' + responseAction.error.message,
  //           400,
  //           'error',
  //           false
  //         )
  //       } else {
  //         errorStatus.addMessage('Failed to fetch data.', 400, 'error', false)
  //       }
  //     } catch (error) {
  //       errorStatus.addMessage('Error: ' + error, 400, 'error', false)
  //     }
  //   } else {
  //     errorStatus.addMessage(
  //       'Please correct the highlighted input errors and try again.',
  //       400,
  //       'error',
  //       true
  //     )
  //   }
  // }
  const setRouteName = (e) => {
    setRouteArray([
      ...routeArray.slice(0, selectedIndex),
      {
        ...routeArray[selectedIndex],
        routeName: e.target.value,
      },
      ...routeArray.slice(selectedIndex + 1, routeArray.length - 1),
    ])
  }
  const saveRoute = async () => {
    const route = routeArray[selectedIndex]
    const req = {
      startTime: route.selectedDate.getTime(),
      speedKts: route.speedVal,
      waypoints,
      yellowCeilingFeet: route.yellow1,
      redCeilingFeet: route.red1,
      yellowVisibilityMiles: route.yellow2,
      redVisibilityMiles: route.red2,
      format: 'GEO_JSON',
    }
    const res = {
      ...evalApiResponse,
    }
    try {
      const response = await dispatch(
        saveRouteApi({
          label: routeArray[selectedIndex].routeName,
          req,
          res,
        })
      )
      if (response.error) {
        errorStatus.addMessage(response.payload.message, 400, 'error', true)
      }
      if (response.meta.requestStatus === 'fulfilled') {
        errorStatus.addMessage('Route was saved.', 200, 'success', true)
      }
    } catch (error) {
      errorStatus.addMessage(
        'Something went wrong. Please try again.',
        400,
        'error',
        true
      )
    }
  }

  const handleRouteSwitch = (idx: number) => {
    if (showSaveMenu) {
      setShowSaveMenu(false)
    }
    dispatch(setSelectedIndex(idx))
  }

  useEffect(() => {
    setDisableRouteDeleteBtn(routeArray.length > 1)
    setDisableAddBtn(routeArray.length === 3)
  }, [routeArray])

  return (
    <>
      {showRC && (
        <div className="relative">
          <div className="absolute top-0 left-0 w-full">
            {showComponent && (
              <div
                className={`flex flex-col justify-start bg-white mb-4 pb-2 pl-10 pt-2 
                  transition-all duration-1000 ease-in-out h-fit relative z-[99999]`}
                onMouseOver={() => {
                  leafletMap.dragging.disable()
                  leafletMap.doubleClickZoom.disable()
                }}
                onMouseOut={() => {
                  leafletMap.dragging.enable()
                  leafletMap.doubleClickZoom.enable()
                }}
                onFocus={() => {
                  leafletMap.dragging.disable()
                  leafletMap.doubleClickZoom.disable()
                }}
                onBlur={() => {
                  leafletMap.dragging.enable()
                  leafletMap.doubleClickZoom.enable()
                }}
              >
                <div className="flex flex-wrap">
                  <div className="w-full">
                    <ul
                      className="flex mb-0 list-none flex-wrap pb-4 flex-row"
                      role="tablist"
                    >
                      {routeArray.map((el, idx) => (
                        <li
                          className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                          key={idx}
                        >
                          <button
                            className={
                              'hover:ease-in duration-300 text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                              (selectedIndex === idx && !showSaveMenu
                                ? 'text-white bg-teal-600'
                                : 'text-teal-600 bg-white')
                            }
                            onClick={() => handleRouteSwitch(idx)}
                            data-toggle="tab"
                          >
                            <i className="fas fa-space-shuttle text-base mr-1"></i>{' '}
                            {`Route-${idx + 1}`}
                          </button>
                        </li>
                      ))}
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <button
                          className={
                            'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                            (showSaveMenu
                              ? 'text-white bg-teal-600'
                              : 'text-teal-600 bg-white')
                          }
                          onClick={() => setShowSaveMenu(true)}
                          data-toggle="tab"
                        >
                          <i className="fas fa-space-shuttle text-base mr-1"></i>{' '}
                          {`Saved Routes`}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                {!showSaveMenu && (
                  <>
                    <h2 className="font-bold">Environmental</h2>
                    <hr />
                    <div className="inline-grid grid-cols-4 gap-4 pt-2">
                      <div className="text-sm mr-2 pb-2">
                        <span>Pre-Selected Areas:</span>
                        <input
                          type="text"
                          defaultValue="Grand Forks"
                          className="border border-gray-300 text-gray-900 text-base rounded-lg block w-full h-10 pl-2"
                        />
                      </div>
                      <div className="text-sm mr-2 pb-2">
                        <span className="hidden">Micro Models</span>
                        <input
                          type="text"
                          defaultValue="CW100NY1, CW100NC1, CW100NC2"
                          className="hidden border border-gray-300 text-gray-900 text-base rounded-lg block w-full h-10 pl-2"
                        />
                      </div>
                      <div className="text-sm mr-2 pb-2">
                        <span className="hidden">Regional Models</span>
                        <input
                          type="text"
                          defaultValue="HRRR, HRDPSCCCCnt, NDFD, MMIX, GFS, LAMF"
                          className="hidden border border-gray-300 text-gray-900 text-base rounded-lg block w-full h-10 pl-2"
                        />
                      </div>
                    </div>

                    <h2 className="font-bold pt-2">Flight Plan</h2>
                    <hr />
                    <div className="inline-grid grid-cols-4 gap-6 pt-2 pb-2">
                      <div className="text-sm">
                        <span>Waypoints (deg, deg, m)</span>
                        <input
                          value={waypoints}
                          onChange={(e) => {
                            dispatch(setWaypoints(e.target.value))
                          }}
                          className={`border ${
                            waypointsError
                              ? 'border-red-500'
                              : 'border-gray-300'
                          } text-gray-900 text-base rounded-lg block w-full !important h-10 pl-2`}
                        />
                      </div>
                      <div className="text-sm">
                        <span>Start Time(Local)</span>
                        <div className="block">
                          <DatePicker
                            wrapperClassName="w-full"
                            selected={routeArray[selectedIndex].selectedDate}
                            onChange={handleDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="Time"
                            className={`border ${
                              dateError ? 'border-red-500' : 'border-gray-300'
                            } text-gray-900 text-base rounded-lg block w-full !important h-10 pl-2`}
                          />
                        </div>
                      </div>
                      <div className="text-sm ">
                        <span>Speed (Kts)</span>
                        <input
                          type="number"
                          value={routeArray[selectedIndex].speedVal}
                          onChange={handleSpeedChange}
                          className={`border ${
                            flightSpeedError
                              ? 'border-red-500'
                              : 'border-gray-300'
                          } text-gray-900 text-base rounded-lg block w-full h-10 pl-2`}
                        />
                      </div>
                    </div>

                    <h2 className="font-bold pt-2 ">Thresholds</h2>
                    <hr />
                    <div className="inline-flex pt-2">
                      <div className="flex flex-row text-sm m-2">
                        <p className="text-sm mt-1.5">Parameters:</p>
                        <label htmlFor="winds">
                          <input
                            type="radio"
                            name="parameter"
                            value="winds"
                            checked={
                              routeArray[selectedIndex].selectedParameter ===
                              'winds'
                            }
                            onChange={handleChange}
                            className="mx-2 mt-1.5"
                          />
                          Winds
                        </label>
                        <label htmlFor="cv">
                          <input
                            type="radio"
                            name="parameter"
                            value="ceilingVisibility"
                            checked={
                              routeArray[selectedIndex].selectedParameter ===
                              'ceilingVisibility'
                            }
                            onChange={handleChange}
                            className="mx-2 mt-1.5"
                          />
                          Ceiling/Visibility
                        </label>
                      </div>

                      <div className="flex flex-row text-sm m-2">
                        <p className="mx-6 mt-1.5">
                          {routeArray[selectedIndex].selectedParameter ===
                          'winds'
                            ? 'Winds at Takeoff/Landing(Kts):'
                            : 'Ceiling (feet AGL):'}
                        </p>
                        <p className="mt-1.5">Yellow</p>
                        <input
                          type="text"
                          // value={yellow1}
                          value={
                            routeArray[selectedIndex].selectedParameter ===
                            'winds'
                              ? routeArray[selectedIndex].windSpeedInputs.yellow
                              : routeArray[selectedIndex].ceilingInputs.yellow
                          }
                          onChange={handleYellow1Change}
                          onBlur={handleYellow1Blur}
                          className={`border ${
                            yellow1Error ? 'border-red-500' : 'border-gray-300'
                          } text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`}
                        />
                        <p className="mt-1.5">Red</p>
                        <input
                          type="text"
                          // value={red1}
                          value={
                            routeArray[selectedIndex].selectedParameter ===
                            'winds'
                              ? routeArray[selectedIndex].windSpeedInputs.red
                              : routeArray[selectedIndex].ceilingInputs.red
                          }
                          onChange={handleRed1Change}
                          onBlur={handleRed1Blur}
                          className={`border ${
                            red1Error ? 'border-red-500' : 'border-gray-300'
                          } text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`}
                        />
                      </div>

                      <div className="flex flex-row text-sm m-2">
                        <p className="mx-6 mt-1.5">
                          {routeArray[selectedIndex].selectedParameter ===
                          'winds'
                            ? '250FT Winds (Kts):'
                            : 'Visibility (miles):'}
                        </p>
                        <p className="mt-1.5">Yellow</p>
                        <input
                          type="text"
                          // value={yellow2}
                          value={
                            routeArray[selectedIndex].selectedParameter ===
                            'winds'
                              ? routeArray[selectedIndex].eightyMWindInputs
                                  .yellow
                              : routeArray[selectedIndex].visibilityInputs
                                  .yellow
                          }
                          onChange={handleYellow2Change}
                          onBlur={handleYellow2Blur}
                          className={`border ${
                            yellow2Error ? 'border-red-500' : 'border-gray-300'
                          } text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`}
                        />
                        <p className="mt-1.5">Red</p>
                        <input
                          type="text"
                          // value={red2}
                          value={
                            routeArray[selectedIndex].selectedParameter ===
                            'winds'
                              ? routeArray[selectedIndex].eightyMWindInputs.red
                              : routeArray[selectedIndex].visibilityInputs.red
                          }
                          onChange={handleRed2Change}
                          onBlur={handleRed2Blur}
                          className={`border ${
                            red2Error ? 'border-red-500' : 'border-gray-300'
                          } text-gray-900 text-base rounded-lg block w-14 h-8  mx-2 pl-2`}
                        />
                      </div>
                    </div>
                    <h2 className="font-bold pt-2">Optimization</h2>
                    <hr />
                    <div className="inline-grid grid-cols-4 gap-6 pt-2">
                      <div>
                        <p className="text-sm mb-2 pt-2">Cost to Minimize</p>
                        <select
                          value={routeArray[selectedIndex].selectedOpValue}
                          onChange={handleOpChange}
                          className="border border-gray-300 text-gray-900 text-base block w-full rounded-lg h-10 pl-2"
                          disabled
                        >
                          <option value="WIND_RISK">Wind Risk</option>
                          <option value="WIND_IMPACTED_TRAVEL_TIME">
                            Wind Impacted Travel Time
                          </option>
                        </select>
                      </div>

                      <div className="text-sm ml-5 pt-2">
                        <p className="mb-2 ">Wind Aversion</p>
                        <label htmlFor="low" className="m-2">
                          low
                        </label>
                        <input
                          type="range"
                          min={0}
                          max={10}
                          value={routeArray[selectedIndex].sliderValue}
                          onChange={handleSliderChange}
                          className="mt-1.5"
                          disabled
                        />
                        <label htmlFor="high" className="m-2">
                          high
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-row items-end h-10 gap-6 text-sm w-full mt-8">
                      <div>
                        <span>Route Name</span>
                        <input
                          value={routeArray[selectedIndex].routeName}
                          onChange={setRouteName}
                          className={`border border-gray-300
                          text-gray-900 text-base rounded-lg block w-full h-10 pl-2`}
                        />
                      </div>
                      <button
                        className="p-2 text-center px-5 py-2 whitespace-nowrap text-white bg-green-500 w-30  h-10 rounded uppercase"
                        type="button"
                        data-ripple-light="true"
                        onClick={saveRoute}
                      >
                        Save Route
                      </button>
                    </div>

                    <div className="inline-flex gap-8 mt-6 font-lato w-full text-base">
                      <div className="p-2.5">
                        <button
                          onClick={handleEvaluateClick}
                          className="p-2 text-center px-5 py-2 text-white bg-green-500 w-30 h-10 rounded uppercase"
                        >
                          Evaluate
                        </button>
                      </div>
                      {/* <div className="py-2.5 my-0 mx-5 relative"> */}
                      {/* <button
                          onClick={handleOptimizeClick}
                          className={`text-center px-5 py-2 text-white bg-gray-500 w-30 h-10 rounded uppercase ${
                            showTooltip ? 'cursor-pointer' : 'cursor-not-allowed'
                          }`}
                          onMouseEnter={handleTooltipHover}
                          onMouseLeave={handleTooltipLeave}
                        >
                          Optimize
                        </button> */}
                      {/* {showTooltip && (
                          <div className="absolute top-10 left-20 opacity-100 bg-gray-700 text-sm text-white p-2 rounded shadow-md transition-opacity duration-300">
                            <div
                              className="overflow-hidden"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              We currently do not support optimizing routes
                            </div>
                          </div>
                        )} */}
                      {/* </div> */}
                      <div className="py-2.5">
                        <button
                          onClick={handleClearWaypoints}
                          className="p-2 text-center px-5 py-2 whitespace-nowrap text-white bg-green-500 w-30  h-10 rounded uppercase"
                        >
                          Clear Waypoints
                        </button>
                      </div>
                      <div className="py-2.5">
                        <button
                          onClick={addNewRoute}
                          className="disabled:bg-green-200 disabled:cursor-not-allowed p-2 text-center px-5 py-2 whitespace-nowrap text-white bg-green-500 w-30  h-10 rounded uppercase"
                          disabled={disableAddBtn}
                        >
                          Add new route
                        </button>
                      </div>
                      <div className="py-2.5">
                        <button
                          onClick={deleteCurrentRoute}
                          className="disabled:bg-red-200 disabled:cursor-not-allowed p-2 text-center px-5 py-2 whitespace-nowrap text-white bg-red-500 w-30  h-10 rounded uppercase"
                          disabled={!disableRouteDeleteBtn}
                        >
                          DELETE ROUTE
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {showSaveMenu && (
                  <SavedRoutesMenu
                    routes={routeArray}
                    setRouteArray={setRouteArray}
                    setShowSaveMenu={setShowSaveMenu}
                  />
                )}
              </div>
            )}
            {}
            <div className="flex flex-col items-center">
              <button
                className={`font-bold absolute rounded-full overflow-hidden ${
                  showComponent ? 'mt-[-20px]' : 'mt-[-12px]'
                }  mr-46  z-[900] focus:outline-none `}
                onClick={handleButtonClick}
              >
                <img
                  alt="chevron"
                  className={`rounded-full transform ${
                    showComponent ? 'rotate-0' : 'rotate-180'
                  }`}
                  src={chevronDown}
                  width="70"
                  height="70"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RouteCAST
