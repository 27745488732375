import { Marker, Popup } from "react-leaflet";
import L from 'leaflet'
import triangle from '../../assets/triangle.svg'
import darkmodeTriangle from '../../assets/darkmode-triangle.svg'
import '../CustomMapMarker/CustomMapMarker.css'
import { CustomMapMarkerProps } from "./CustomMapMarker-changes";
import starRed from '../../assets/starred.svg'
import starGreen from '../../assets/stargreen.svg'
import starYellow from '../../assets/staryellow.svg'
import MapPopupTable from "../MapPopupTable/MapPopupTable";
import { v4 as uuidv4 } from "uuid";

// import { NetworkData } from "../../interfaces/networkData";

const TAFMarker = ({ feature, darkMode, tableView, popupContent }:CustomMapMarkerProps) => {
    const iconUrl = darkMode? darkmodeTriangle : triangle
    const mapIcon = new L.Icon({
        iconUrl: iconUrl,
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        popupAnchor: [0, -12],
        className: darkMode? 'brightness-1000 contrast-10000' : '',
    });

    return (
        <Marker
            // key={feature?.id}
            position={[feature.latitude, feature.longitude]}
            icon={mapIcon}
        >
            <Popup className="custom-popup">
                {tableView ? (
                  <MapPopupTable properties={feature.properties} />
                ): (
                  {popupContent}
                )}
            </Popup>
        </Marker>
    )
}
    
export const METARMarker = ({ feature, darkMode, tableView, popupContent }: CustomMapMarkerProps) => {
  const iconurl = markerMetarTAFMarker(feature, darkMode)
    const mapIcon = new L.Icon({
        iconUrl: iconurl,
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        popupAnchor: [0, -12],
        className: darkMode? 'brightness-1000 contrast-10000' : '',
    });


  return (
      <Marker
        key={uuidv4()}
        position={[feature.latitude, feature.longitude]}
        icon={mapIcon}
      >
        <Popup className="custom-popup">
            {tableView ? (
              <MapPopupTable properties={feature.properties} />
            ): (
              {popupContent}
            )}
        </Popup>
      </Marker>
  )

}

export const markerMetarTAFMarker = (feature, darkmode: boolean) => {
  if (feature.properties['Report Type']?.value === 'METAR') {
    switch (metarColorMap(feature)) {
      case 'red':
        return starRed
      case 'yellow':
        return starYellow
      case 'green':
        return starGreen
      default:
        return starGreen
    }
  } else if (feature.properties?.Data == 'TAF') {
    return darkmode ? darkmodeCircle : circle
  } else {
    return darkmode ? darkmodeTriangle : triangle
  }
}    

const metarColorMap = (feature) => {
  const properties = feature?.properties

  let windValue = properties['Wind Speed'].value,
    ceilingValue = properties['Ceiling'].value,
    temperatureValue = properties['Temperature'].value,
    visibilityValue = properties['Visibility'].value,
    windGustValue = properties['Wind Gust'].value

  if (ceilingValue === 'No Ceiling') {
    ceilingValue = 10000
  }
  if (temperatureValue === 'N/A') {
    temperatureValue = 21
  }
  if (windValue === 'N/A') {
    windValue = 4
  }

  if (visibilityValue === 'N/A' || !visibilityValue) {
    visibilityValue = 10
  }

  if (windGustValue === 'N/A' || windGustValue === 'NONE' || !windGustValue) {
    windGustValue = 13
  }

  if (
    windValue > 15 ||
    windValue < -3.9 ||
    temperatureValue > 35 ||
    temperatureValue < -4 ||
    ceilingValue <= 600 ||
    visibilityValue <= 3 ||
    windGustValue > 24
  ) {
    return 'red'
    // return starRed
  }

  if (
    (windValue > 8 && windValue <= 15) ||
    (temperatureValue >= -3.9 && temperatureValue < 2.2) ||
    (temperatureValue >= 31.6 && temperatureValue <= 35) ||
    (ceilingValue < 1000 && ceilingValue > 600) ||
    (visibilityValue < 4 && visibilityValue > 3) ||
    (windGustValue >= 14 && windGustValue <= 24)
  ) {
    return 'yellow'
    // return starYellow
  }
  return 'green'
  // return starGreen
}

export default TAFMarker;