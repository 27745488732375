import { useEffect, useState, useRef, useCallback } from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { TWS_API_URL } from '../../environment/apis.config'
import Toast, { toastPosition, toastType } from '../Toast/Toast'
import { toPng } from 'html-to-image'
import markerImg from '../../assets/vertical-profile_marker.png'
import L from 'leaflet'
import './VerticalProfile.css'
import skewtThumbnailImage from '../../assets/skewtThubnail.png'
import ZoomableContent from '../ZoomableContent/ZoomableContent'
import { timeouts } from '../../services/RequestTimeouts'
import useErrorStatus from '../../hooks/UseErrorStatus'
import { deselectMenuItemById } from '../../menus/SideNavigation/SideNavigationSlice'

interface VerticalProfileInterface {
  timestamp: string
  StationID: string
  url: string
  coordinates: Record<string, unknown>
  csv_file: string
}

const VerticalProfile = () => {
  const [verticalProfile, setWeather] = useState<VerticalProfileInterface>({})
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [showVerticalProfile, setverticalProfileMarker] = useState(false)
  const [type, setToastType] = useState(toastType.success)
  const location = useSelector((state) => state.user.defaultLocation)
  const elementRef = useRef<HTMLDivElement>(null)
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [timedOut, setTimedOut] = useState<boolean>(false)
  const controller = new AbortController()
  const errorStatus = useErrorStatus()
  const dispatch = useDispatch()
  const [sortedHeight, setHeights] = useState<string[]>([])
  const url = `${TWS_API_URL}/weatherobs`
  const timeoutId = setTimeout(() => {
    controller.abort('API call timed out')
    clearTimeout(timeoutId)
    setTimedOut(true)
  }, timeouts.verticalProfile)

  const getData = async () => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lat: location.latitude,
          long: location.longitude,
          inteldevice: [],
          tempestToken: [],
        }),
        signal: controller.signal,
      })
      const data = await response.json()
      if (data.message == 'Not Authorized.') {
        errorStatus.addMessage('User not authorized', 400, 'error', true)
      } else if (
        data.message != 'No weather data at this location' &&
        data.features.length > 0 &&
        Object.keys(data.features[0].properties).length >= 0 &&
        (data.features[0].properties.hasOwnProperty(
          'meteodrone_vertical_profile'
        ) ||
          data.features[0].properties.hasOwnProperty('nrg_vertical_profile'))
      ) {
        errorStatus.addMessage(
          'Vertical profile data availabe in the current location',
          400,
          'success',
          false,
          10000
        )
        if (
          data.features[0].properties.hasOwnProperty(
            'meteodrone_vertical_profile'
          )
        ) {
          const metrodroneData =
            data.features[0].properties['meteodrone_vertical_profile']
          const numKeys = []
          for (const key in metrodroneData) {
            if (!isNaN(key)) numKeys.push(key)
          }
          numKeys.sort((a, b) => parseFloat(a) - parseFloat(b))
          setWeather({
            ...metrodroneData,
          })
          setHeights(numKeys)
        }
        if (
          data.features[0].properties.hasOwnProperty('nrg_vertical_profile')
        ) {
          setWeather({
            ...data.features[0].properties['nrg_vertical_profile'],
          })
        }
        setverticalProfileMarker(true)
      } else {
        errorStatus.addMessage(
          'No vertical profile data availabe in the current location',
          400,
          'error',
          true,
          7000
        )
        // deselect vertical profile menu
        dispatch(deselectMenuItemById('vertical-profile'))
      }
    } catch (error) {
      if (error.message === 'The user aborted a request.') {
        // set by abort controller
        dispatch(deselectMenuItemById('vertical-profile'))
        errorStatus.addMessage(
          'Vertical profile request timed out. Please try again.',
          400,
          'error',
          true
        )
      } else {
        errorStatus.addMessage(error.message, 400, 'error', true)
      }
    }
  }
  // Trigger when we load the page
  useEffect(() => {
    getData()
  }, [location])

  const color_maping = (key: string, val: any) => {
    const base_string = 'rounded-md border border-gray-500 m-1 p-0.5 '
    let color_string = 'bg-gray-300'
    if (isNaN(val)) {
      return base_string + color_string
    }
    let low_temp
    let high_temp
    if (key == 'temp') {
      if (val >= 2) low_temp = 'green'
      else if (val < 2 && val >= -4) low_temp = 'yellow'
      else low_temp = 'red'

      if (val <= 32) high_temp = 'green'
      else if (val > 32 && val < 34) high_temp = 'yellow'
      else high_temp = 'red'

      if (low_temp === 'green' && high_temp === 'green') {
        color_string = 'bg-green-300'
      }
      if (low_temp === 'yellow' || high_temp === 'yellow') {
        color_string = 'bg-yellow-300'
      }
      if (low_temp === 'red' || high_temp === 'red') {
        color_string = 'bg-red-300'
      }
    } else if (key == 'wind') {
      if (val <= 8) color_string = 'bg-green-300'
      else if (val > 8 && val <= 14) color_string = 'bg-yellow-300'
      else color_string = 'bg-red-300'
    }
    return base_string + color_string
  }

  const htmlToImageConvert = useCallback(() => {
    if (elementRef.current === null) {
      return
    }
    toPng(elementRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a')
        const fileName = 'VerticalProfile-' + Date.now() + '.png'
        link.download = fileName
        link.href = dataUrl
        link.click()
        errorStatus.addMessage(
          'PNG exported: ' + fileName,
          400,
          'success',
          false
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [elementRef])

  const markerIcon = new L.Icon({
    iconUrl: markerImg,
    iconSize: [24, 36],
    iconAnchor: [12, 12],
    popupAnchor: [0, -12],
  })

  const downloadCSVFile = async () => {
    const link = document.createElement('a')
    link.href = verticalProfile['csv_file']
    link.setAttribute('download', `raw_csv_file.csv`)
    document.body.appendChild(link)
    link.click()
  }

  const map = useMapEvents({
    popupclose: () => {
      setModalActive(false)
    },
  })

  const renderSkewtModal = () => {
    return (
      <Popup
        className="popup-skewt-image"
        position={[location.latitude, location.longitude]}
        autoClose={false}
      >
        <img
          src={verticalProfile['url']}
          alt="skewt diagram"
          className="skewt-image"
        />
      </Popup>
    )
  }

  return (
    <>
      {showVerticalProfile ? (
        <Marker
          position={[
            verticalProfile['coordinates']['latitude'],
            verticalProfile['coordinates']['longitude'],
          ]}
          title={'Vertical Profile'}
          icon={markerIcon}
        >
          <Popup autoClose={false} className="vertical-profile-popup">
            <button
              type="button"
              onClick={htmlToImageConvert}
              className="text-sm w-max cursor-pointer rounded bg-gray-300 block p-1 hover:bg-gray-500 inline-block mb-1"
            >
              Export Data
              <svg
                className="w-4 h-4 ml-1 text-gray-800 dark:text-white inline-block"
                aria-hidden="true"
                xmlns="
                          http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                />
              </svg>
            </button>
            {verticalProfile['SensorType'] &&
              verticalProfile['SensorType']['value'] === 'Meteodrone' && (
                <button
                  type="button"
                  onClick={downloadCSVFile}
                  className="text-sm w-max cursor-pointer rounded bg-gray-300 block p-1 hover:bg-gray-500 inline-block mb-1"
                >
                  Download CSV file
                  <svg
                    className="w-4 h-4 ml-1 text-gray-800 dark:text-white inline-block"
                    aria-hidden="true"
                    xmlns="
                          http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 18"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                    />
                  </svg>
                </button>
              )}
            {verticalProfile['SensorType'] &&
              verticalProfile['SensorType']['value'] === 'Meteodrone' &&
              verticalProfile['url'] !== '' && (
                <div
                  className="cursor-pointer"
                  onClick={() => setModalActive(true)}
                >
                  <img className="h-40 w-full" src={skewtThumbnailImage}></img>
                </div>
              )}
            {verticalProfile['SensorType'] !== undefined ? (
              <div className="text-sm font-normal bg-white" ref={elementRef}>
                <b>Vertical Profile</b>
                <hr className="h-px my-1 bg-black" />
                <b>Station ID : </b> {verticalProfile['StationID']} <br />
                <b>Sensor Type : </b>
                {verticalProfile['SensorType']['value']}
                <br />
                <b>Timestamp : </b>
                {verticalProfile['timestamp']} <br />
                <b>Altitude Units : </b> FEET <br />
                <table className="min-w-full leading-normal  border-slate-500">
                  <thead>
                    <tr>
                      <th className="p-2 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left uppercase">
                        Ht
                      </th>
                      <th className="p-2 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left uppercase">
                        Values
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedHeight.map(
                      (key, index) =>
                        !isNaN(key) &&
                        Number(key) >= 0 && (
                          <tr key={index}>
                            <td className="p-2 border-b border-gray-200 bg-white border border-slate-700 text-xl">
                              {Math.round(Number(key) / 100) * 100}
                            </td>
                            <td className="p-2 border-b border-gray-200 bg-white border border-slate-700">
                              <ul className="list-none">
                                <li
                                  className={color_maping(
                                    'wind',
                                    verticalProfile[key]['WindSpeed']
                                      ? verticalProfile[key]['WindSpeed'][
                                          'value'
                                        ]
                                      : 'NA'
                                  )}
                                >
                                  Wind Dir/Speed:{' '}
                                  {
                                    verticalProfile[key]['WindDirection'][
                                      'value'
                                    ]
                                  }
                                  °/{' '}
                                  {verticalProfile[key]['WindSpeed'] !==
                                  undefined
                                    ? `${verticalProfile[key]['WindSpeed']['value']} ${verticalProfile[key]['WindSpeed']['units']}`
                                    : 'NA'}
                                </li>
                                <li
                                  className={color_maping(
                                    'temp',
                                    verticalProfile[key]['Temperature']['value']
                                  )}
                                >
                                  Temp:{' '}
                                  {verticalProfile[key]['Temperature']['value']}
                                  {verticalProfile[key]['Temperature']['units']}
                                </li>
                                <li className="rounded-md border border-gray-500 m-1 p-0.5 bg-gray-300">
                                  DP:{' '}
                                  {
                                    verticalProfile[key]['DewpointTemperature'][
                                      'value'
                                    ]
                                  }
                                  {
                                    verticalProfile[key]['DewpointTemperature'][
                                      'units'
                                    ]
                                  }
                                </li>
                                <li className="rounded-md border border-gray-500 m-1 p-0.5 bg-gray-300">
                                  Air Pressure:{' '}
                                  {verticalProfile[key]['AirPressure']['value']}
                                  {verticalProfile[key]['AirPressure']['units']}
                                </li>
                              </ul>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-sm font-normal bg-white" ref={elementRef}>
                <b>Vertical Profile</b>
                <hr className="h-px my-1 bg-black" />
                <b>Station ID : </b> {verticalProfile['StationID']} <br />
                <b>Sensor Type:</b> {'NRG'}
                <b>Timestamp : </b>
                {verticalProfile['timestamp']}
                <br />
                <b>Altitude Units : </b>
                {verticalProfile['altitude_units'].toUpperCase()}
                <br />
                <table className="min-w-full leading-normal  border-slate-500">
                  <thead>
                    <tr>
                      <th className="p-2 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left uppercase">
                        Ht
                      </th>
                      <th className="p-2 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left uppercase">
                        Values
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(verticalProfile).map(
                      ([key]) =>
                        !isNaN(key) &&
                        Number(key) >= 0 && (
                          <tr>
                            <td className="p-2 border-b border-gray-200 bg-white border border-slate-700 text-xl">
                              {Math.round(Number(key) / 5) * 5}
                            </td>
                            <td className="p-2 border-b border-gray-200 bg-white border border-slate-700">
                              <ul className="list-none">
                                {key == '0' && (
                                  <>
                                    <li
                                      className={color_maping(
                                        'temp',
                                        verticalProfile[key]['Temperature'][
                                          'value'
                                        ]
                                      )}
                                    >
                                      Temperature:{' '}
                                      {
                                        verticalProfile[key]['Temperature'][
                                          'value'
                                        ]
                                      }{' '}
                                      {!isNaN(
                                        verticalProfile[key]['Temperature'][
                                          'value'
                                        ]
                                      ) &&
                                        verticalProfile[key]['Temperature'][
                                          'units'
                                        ]}
                                    </li>
                                    <li className="rounded-md border border-gray-500 m-1 p-0.5 bg-gray-300">
                                      Humidity:{' '}
                                      {
                                        verticalProfile[key]['Humidity'][
                                          'value'
                                        ]
                                      }{' '}
                                      {!isNaN(
                                        verticalProfile[key]['Humidity'][
                                          'value'
                                        ]
                                      ) &&
                                        verticalProfile[key]['Humidity'][
                                          'units'
                                        ]}
                                    </li>
                                    <li className="rounded-md border border-gray-500 m-1 p-0.5 bg-gray-300">
                                      Pressure:{' '}
                                      {
                                        verticalProfile[key]['AirPressure'][
                                          'value'
                                        ]
                                      }{' '}
                                      {!isNaN(
                                        verticalProfile[key]['AirPressure'][
                                          'value'
                                        ]
                                      ) &&
                                        verticalProfile[key]['AirPressure'][
                                          'units'
                                        ]}
                                    </li>
                                  </>
                                )}
                                <li className="rounded-md border border-gray-500 m-1 p-0.5 bg-gray-300">
                                  WindDirection:{' '}
                                  {
                                    verticalProfile[key]['WindDirection'][
                                      'value'
                                    ]
                                  }{' '}
                                  {!isNaN(
                                    verticalProfile[key]['WindDirection'][
                                      'value'
                                    ]
                                  ) &&
                                    verticalProfile[key]['WindDirection'][
                                      'units'
                                    ]}
                                </li>
                                <li
                                  className={color_maping(
                                    'wind',
                                    verticalProfile[key]['HorizontalWindSpeed'][
                                      'value'
                                    ]
                                  )}
                                >
                                  HorizontalWindSpeed:{' '}
                                  {
                                    verticalProfile[key]['HorizontalWindSpeed'][
                                      'value'
                                    ]
                                  }{' '}
                                  {!isNaN(
                                    verticalProfile[key]['HorizontalWindSpeed'][
                                      'value'
                                    ]
                                  ) &&
                                    verticalProfile[key]['HorizontalWindSpeed'][
                                      'units'
                                    ]}
                                </li>
                                <li className="rounded-md border border-gray-500 m-1 p-0.5 bg-gray-300">
                                  VerticalWindSpeed:{' '}
                                  {
                                    verticalProfile[key]['VerticalWindSpeed'][
                                      'value'
                                    ]
                                  }{' '}
                                  {!isNaN(
                                    verticalProfile[key]['VerticalWindSpeed'][
                                      'value'
                                    ]
                                  ) &&
                                    verticalProfile[key]['VerticalWindSpeed'][
                                      'units'
                                    ]}
                                </li>
                              </ul>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </Popup>
        </Marker>
      ) : (
        <></>
      )}
      {modalActive &&
        verticalProfile['SensorType']['value'] === 'Meteodrone' &&
        renderSkewtModal()}
      {showToast ? (
        <Toast
          toastMessage={toastMessage}
          toastPosition={toastPosition.topRight}
          toastType={type}
          onClickCallback={toastCallbackFn}
        />
      ) : (
        <div></div>
      )}
    </>
  )
}
export default VerticalProfile
