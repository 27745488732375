import {
  Popup,
  CircleMarker,
  Polygon,
  Polyline,
  Marker,
  Circle,
} from 'react-leaflet'
import { ReactElement, useEffect, useState } from 'react'
import MapPopupTable from '../MapPopupTable/MapPopupTable'
import '../CustomMapMarker/CustomMapMarker.css'
import NetworkData from '../../interfaces/networkData'
import AlertBox from '../Alerting/AlertingBox/AlertingBox'
import { useSelector } from 'react-redux'
import useAlertSetting from '../Alerting/useAlertSetting'
import { elements } from '../../interfaces/alertingelements'
import EventBox from '../Alerting/AlertingBox/EventBox'
import PolygonPopupTable from '../MapPopupTable/PolygonPopupTable'
import { RootState } from '../../store'
import { AirmetPolygon } from './PolygonMapMarker'
import TAFMarker, { METARMarker } from './TAFMETARMarker'

interface CustomMapMarkerProps {
  index: number
  feature: NetworkData
  tableView: boolean
  popupContent?: React.ReactElement | null
  networkData: NetworkData[]
}

const CustomMapMarker = ({
  index,
  feature,
  tableView,
  popupContent,
  networkData,
}: CustomMapMarkerProps) => {
  const darkMode = useSelector((state: RootState) => state.mapState.darkMode)
  const { openAlertSetting, closeAlertSetting } = useAlertSetting()
  const removeAlert = useSelector((state) => state.alert.removeAlert)
  const [inPolygons, setInPolygons] = useState()

  const removeAlertForParticularHazard = useSelector(
    (state) => state.alert.removeAlertForParticularHazard
  )
  const alertNotificationCalled = useSelector(
    (state) => state.alert.callNetworkAdapter
  )
  const specificAlertCalled = useSelector(
    (state) => state.alert.callSpecificAlert
  )
  const addAlert = useSelector((state) => state.alert.sideNavAlerts)
  const showAlertNotificat = useSelector(
    (state) => state?.alert?.showAlertNotification
  )
  const showEventList = useSelector((state) => state.alert.showEventList)
  const showEventsIfAlertNotPresent = useSelector(
    (state) => state.alert.showEventsIfAlertNotPresent
  )
  const handlePopupClick = (properties) => {
    openAlertSetting(properties)
    closeAlertSetting()
  }

  let markerComponent
  if (feature.properties === undefined) {
    console.log('feature undefined', feature)
  } else if (
    Object.keys(feature.properties).includes('Raw TAF') ||
    feature.properties['Report Type']?.value == 'METAR' ||
    feature.properties?.data == 'AIREP' ||
    feature.properties.airepType
  ) {
      Object.keys(feature.properties).includes('Raw TAF') ? (
        markerComponent = <TAFMarker feature={feature} darkMode={darkMode} tableView={tableView} popupContent={popupContent} />
      ) : feature.properties['Report Type']?.value == 'METAR'? (
        markerComponent = <METARMarker feature={feature} darkMode={darkMode} tableView={tableView} popupContent={popupContent} /> ) : null
  } else if (feature.properties?.airSigmetType == 'AIRMET') {
        markerComponent = <AirmetPolygon feature={feature} darkMode={darkMode} tableView={tableView} popupContent={popupContent} networkData={networkData} />
  } else if (feature.properties?.airSigmetType == 'SIGMET') {
    markerComponent = (
      <AirmetPolygon feature={feature} darkMode={darkMode} tableView={tableView} popupContent={popupContent} networkData={networkData} />
    )
  } else if (
    feature.properties?.StationID ||
    feature.properties?.SensorType?.value
  ) {
    if (feature.properties?.SensorType?.value == 'NRG' || feature.properties?.SensorType?.value == 'Meteodrone'){
      markerComponent = (
        <CircleMarker
          key={index}
          center={[feature.latitude, feature.longitude]}
          radius={6}
          fill={true}
          fillOpacity={0.9}
          fillColor={markerColor(feature)}
          color="black"
          stroke={true}
          weight={1}
          className="text-black border-black border-opacity-100"
        >
          <Popup className="custom-popup">
            {tableView ? (
              <MapPopupTable properties={feature.properties} />
            ) : (
              { popupContent }
            )}
          </Popup>
        </CircleMarker>
      )
    }
    else {
      markerComponent = (
        <CircleMarker
          key={index}
          center={[feature.latitude, feature.longitude]}
          radius={6}
          fill={true}
          fillOpacity={0.9}
          fillColor={markerColor(feature)}
          color="black"
          stroke={true}
          weight={1}
          className="text-black border-black border-opacity-100"
        >
          <Popup className="custom-popup">
            {tableView ? (
              <MapPopupTable properties={feature.properties} />
            ) : (
              { popupContent }
            )}
          </Popup>
        </CircleMarker>
      )
    }
  } else if (
    feature.properties?.messageType &&
    (feature?.properties?.messageType == 'ALERT' ||
      feature?.properties?.messageType == 'CONTINUATION')
  ) {
    if (!specificAlertCalled) {
      if (!removeAlert?.includes(feature.properties?.id)) {
        if (
          !alertNotificationCalled ||
          (alertNotificationCalled &&
            showAlertNotificat?.includes(feature.properties?.id))
        ) {
          if (feature.type == 'Polygon') {
            markerComponent = (
              <>
                <Polygon
                  key={index}
                  fill={markerFillAlert(feature)}
                  fillOpacity={markerFillOpacity(feature)}
                  weight={Number(3)}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  positions={[feature.coordinates]}
                >
                  <Popup className="p-0 custom-popup">
                    <PolygonPopupTable
                      networkData={networkData}
                      popupContent={popupContent}
                      tableView={tableView}
                      feature={feature}
                    ></PolygonPopupTable>
                  </Popup>
                </Polygon>
              </>
            )
          } else if (feature.type == 'Point') {
            markerComponent = (
              <>
                <CircleMarker
                  zIndex={2999999999}
                  key={index}
                  fill={true}
                  fillOpacity={markerFillOpacity(feature)}
                  weight={2.25}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  center={[feature.coordinates[1], feature.coordinates[0]]}
                  radius={18}
                  zIndexOffset={1000}
                >
                  <Popup className="p-0">
                    <div onClick={() => handlePopupClick(feature.properties)}>
                      {tableView ? (
                        <AlertBox properties={feature.properties} />
                      ) : (
                        <>{popupContent}</>
                      )}
                    </div>
                  </Popup>
                </CircleMarker>
              </>
            )
          } else {
            markerComponent = (
              <Polyline
                key={index}
                fill={markerFillAlert(feature)}
                fillOpacity={markerFillOpacity(feature)}
                weight={2.25}
                dashArray={markerDashAlert(feature)}
                fillColor={markerColorAlert(feature)}
                color={markerColorAlert(feature)}
                positions={[feature.coordinates]}
              >
                <Popup className="p-0 custom-popup">
                  <PolygonPopupTable
                    networkData={networkData}
                    popupContent={popupContent}
                    tableView={tableView}
                    feature={feature}
                  ></PolygonPopupTable>
                </Popup>
              </Polyline>
            )
          }
        } else {
          return null
        }
      } else {
        return null
      }
    } else {
      let matchedElement = null
      elements.forEach((element) => {
        if (element.value === feature?.properties?.hazardType) {
          matchedElement = element.id
          return
        }
      })
      if (
        addAlert?.includes(matchedElement) ||
        ((matchedElement == 'NWS Severe Thunderstorm Warning' ||
          matchedElement == 'NWS Tornado Warning') &&
          addAlert?.includes('NWS Alerts'))
      ) {
        if (!removeAlertForParticularHazard?.includes(feature.properties?.id)) {
          if (feature.type == 'Polygon') {
            markerComponent = (
              <>
                <Polygon
                  key={index}
                  fill={markerFillAlert(feature)}
                  fillOpacity={markerFillOpacity(feature)}
                  weight={Number(3)}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  positions={[feature.coordinates]}
                >
                  <Popup className="p-0 custom-popup">
                    <PolygonPopupTable
                      networkData={networkData}
                      popupContent={popupContent}
                      tableView={tableView}
                      feature={feature}
                    ></PolygonPopupTable>
                  </Popup>
                </Polygon>
              </>
            )
          } else if (feature.type == 'Point') {
            markerComponent = (
              <>
                <CircleMarker
                  zIndex={2999999999}
                  key={index}
                  fill={true}
                  fillOpacity={0.3}
                  weight={2.25}
                  dashArray={markerDashAlert(feature)}
                  fillColor={markerColorAlert(feature)}
                  color={markerColorAlert(feature)}
                  center={[feature.coordinates[1], feature.coordinates[0]]}
                  radius={18}
                >
                  <Popup className="p-0">
                    <div onClick={() => handlePopupClick(feature.properties)}>
                      {tableView ? (
                        <AlertBox properties={feature.properties} />
                      ) : (
                        <>{popupContent}</>
                      )}
                    </div>
                  </Popup>
                </CircleMarker>
              </>
            )
          } else {
            markerComponent = (
              <Polyline
                key={index}
                fill={markerFillAlert(feature)}
                fillOpacity={0.3}
                weight={2.25}
                dashArray={markerDashAlert(feature)}
                fillColor={markerColorAlert(feature)}
                color={markerColorAlert(feature)}
                positions={[feature.coordinates]}
              >
                <Popup className="p-0 custom-popup">
                  <PolygonPopupTable
                    networkData={networkData}
                    popupContent={popupContent}
                    tableView={tableView}
                    feature={feature}
                  ></PolygonPopupTable>
                </Popup>
              </Polyline>
            )
          }
        } else {
          return null
        }
      } else {
        return null
      }
    }
  } else if (
    feature?.properties?.type &&
    feature?.properties?.type == 'Event'
  ) {
    if (showEventsIfAlertNotPresent || showEventList) {
      markerComponent = (
        <Polygon
          key={index}
          fill={false}
          weight={Number(3)}
          positions={[feature.bufferGeometry]}
          pathOptions={{ color: 'black' }}
        >
          <Popup className="text-lg">
            {tableView ? (
              <div className="overflow-hidden h-full">
                <EventBox properties={feature.properties} />
              </div>
            ) : (
              <>{popupContent}</>
            )}
          </Popup>
        </Polygon>
      )
    } else {
      return null
    }
  } else {
    return null
  }
  return markerComponent
}

const markerColor = (feature: NetworkData) => {
  let Ceiling, visibility
  const CeilBool = feature?.properties?.Ceiling?.value ? true : false
  const VisBool = feature?.properties?.Visibility ? true : false
  let tempVal = feature?.properties?.Temperature?.value
  let windVal = feature?.properties?.WindSpeed?.value

  if (tempVal === 'NA') {
    // if the temperature is not available set it to 21.0 to force green
    tempVal = 21.0
  }
  if (windVal === 'NA') {
    // if the wind speed is not available set it to 4.0 to force green
    windVal = 4
  }
  if (CeilBool) {
    if (feature?.properties?.Ceiling.value >= 1000) Ceiling = 'Green'
    else if (
      feature?.properties?.Ceiling.value >= 500 &&
      feature?.properties?.Ceiling.value < 1000
    )
      Ceiling = 'Yellow'
    else if (feature?.properties?.Ceiling.value < 500) Ceiling = 'Red'
  }
  if (VisBool) {
    if (feature?.properties?.Visibility?.value >= 5) visibility = 'Green'
    else if (
      feature?.properties?.Visibility?.value >= 3 &&
      feature?.properties?.Visibility?.value < 5
    )
      visibility = 'Yellow'
    else if (feature?.properties?.Visibility?.value < 3) visibility = 'Red'
  }
  if (
    windVal > 15 ||
    windVal < -3.9 ||
    tempVal > 35 ||
    tempVal < -4 ||
    (CeilBool && Ceiling === 'Red') ||
    (VisBool && visibility === 'Red')
  ) {
    return 'red'
  } else if (
    (windVal > 8 && windVal <= 15) ||
    (tempVal >= -3.9 && tempVal < 2.2) ||
    (tempVal >= 31.6 && tempVal <= 35) ||
    (CeilBool && Ceiling === 'Yellow') ||
    (VisBool && visibility === 'Yellow')
  ) {
    return 'yellow'
  } else if (
    windVal <= 8 &&
    tempVal >= 2.2 &&
    tempVal < 31.6 &&
    CeilBool &&
    Ceiling === 'Green' &&
    VisBool &&
    visibility === 'Green'
  ) {
    return 'green'
  } else {
    return 'green'
  }
}
const markerColorAlert = (feature: NetworkData) => {
  let color = '#'
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      color += element?.color
      return
    }
  })
  return color
}
const markerFillAlert = (feature: NetworkData) => {
  let fill = false
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      fill = element?.fill
      return
    }
  })
  return fill
}
const markerFillOpacity = (feature: NetworkData) => {
  let opacity = 0.3
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      opacity = element?.opacity
      return
    }
  })
  return opacity
}
const markerDashAlert = (feature: NetworkData) => {
  let dash
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      if (element?.line === 'dashed') {
        dash = '10'
        return
      } else if (element?.line === 'dot') {
        dash = '3'
        return
      } else {
        dash = '0'
        return
      }
    }
  })
  return dash
}

export default CustomMapMarker
