import { useEffect, useState } from 'react'
import NetworkData from '../../interfaces/networkData'
import AlertBox from '../Alerting/AlertingBox/AlertingBox'
import useAlertSetting from '../Alerting/useAlertSetting'
import MapPopupTable from './MapPopupTable'
import { elements } from '../../interfaces/alertingelements'
import { intersection } from 'polygon-clipping'

const PolygonPopupTable = ({ networkData, feature }: any) => {
  if (feature == undefined) {
    console.log('NO FEATURE PROVIDED ')
    return <></>
  }
  const { openAlertSetting, closeAlertSetting } = useAlertSetting()
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [selectedAlert, setSelectedAlert] = useState(null)
  const [finalTableDatas, setfinalTableDatas] = useState([])
  const [alertData, setAlertData] = useState([])
  const [renderSingleAirSig, setrenderSingleAirSig] = useState(false)
  const [renderSingleAlert, setrenderSingleAlert] = useState(false)
  const overlaps = getOverlaps(feature, networkData)
  if (overlaps.length != 0) {
    networkData = overlaps
    networkData.push(feature)
  }
  const handlePopupClick = (properties) => {
    openAlertSetting(properties)
    closeAlertSetting()
  }
  function activeDropdownHandler(i: number) {
    if (activeDropdown != i) {
      setActiveDropdown(i)
    } else {
      setActiveDropdown(null)
    }
  }
  function selectAlert(i: number) {
    if (selectedAlert != i) {
      setSelectedAlert(i)
    } else {
      setSelectedAlert(null)
    }
  }

  const isPointInPolygon = (point, polygon) => {
    const x = point[0],
      y = point[1]
    let inside = false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0],
        yi = polygon[i][1]
      const xj = polygon[j][0],
        yj = polygon[j][1]

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }
    return inside
  }

  /**
   *
   * @param point The point being tested
   * @param polygons the polygons to test against
   * @returns an array or indices of the polygons that the point is in
   */
  const findPolygonsContainingPoint = (point, polygons) => {
    const indices = []

    polygons.forEach((polygon, index) => {
      // Assuming polygon is an array of coordinates like [[x1, y1], [x2, y2], ...]
      if (isPointInPolygon(point, polygon)) {
        indices.push(index)
      }
    })

    return indices
  }

  useEffect(() => {
    const x = []
    const alertdata = []
    if (overlaps.length == 0) {
      if (
        feature.properties?.airSigmetType == 'SIGMET' ||
        feature.properties?.data == 'AIRMET'
      ) {
        setrenderSingleAirSig(true)
      } else {
        setrenderSingleAlert(true)
      }
    }
    for (let feature in networkData) {
      feature = networkData[feature]
      if (
        feature.properties?.airSigmetType == 'SIGMET' ||
        feature.properties?.data == 'AIRMET'
      ) {
        const properties = feature.properties
        let keys: string[] = []
        const tableData = Object.entries(properties).map(([key, value]) => {
          keys.push(key)
          if (properties.Data && properties.Data == 'TAF' && key != 'Unit') {
            return { key, value: value, units: properties.Unit[key] }
          } else if (
            properties?.['Report Type'] &&
            properties?.['Report Type'].value == 'METAR'
          ) {
            return { key, value: value.value, units: value.unit }
          } else if (
            properties?.data == 'AIRMET' ||
            properties.airSigmetType == 'SIGMET' ||
            properties.data == 'AIREP'
          ) {
            if (key == 'altitudeLow1') {
              return { key, value: value.toString() }
            } else {
              return { key, value: value }
            }
          } else if (properties.Data?.StationID || properties) {
            if (!key.startsWith('CloudLayer')) {
              return { key, value: value.value, units: value.units }
            } else {
              return { key, value: value }
            }
          } else if (properties.Data?.StationID || properties) {
            if (!key.startsWith('CloudLayer')) {
              return { key, value: value.value, units: value.units }
            } else {
              return {
                key,
                value: value.value?.SkyCondition,
                units: value.units,
              }
            }
          } else {
            return { key, value: 'N/A' }
          }
        })
        const order: string[] = [
          'Data',
          'timestamp',
          'Issue Time',
          'Valid Time From',
          'Valid Time To',
          'Valid Time',
          'Site',
          'STID',
          'ID',
          'Cloud Coverage 1',
          'Cloud Coverage 2',
          'Cloud Coverage 3',
          'Cloud Cover',
          'CloudLayer1',
          'CloudLayer2',
          'CloudLayer3',
          'Cloud Layer 1 Value',
          'Cloud Layer 2 Value',
          'Cloud Layer 3 Value',
          'WindSpeed',
          'Wind Speed',
          'Wind Direction',
          'WindDirection',
          'Visibility',
          'Temperature',
          'Dew Point Temperature',
          'DewpointTemperature',
          'Pressure',
          'Sea Level Pressure',
          'Raw TAF',
          'Raw Observation',
        ]
        keys = keys.filter((value) => !order.includes(value))
        const finalTableData = []

        for (const key of order) {
          const value = tableData.find((item) => item.key === key)
          if (value) {
            finalTableData.push(value)
          }
        }
        for (const key of keys) {
          const value = tableData.find((item) => item.key === key)
          if (value) {
            finalTableData.push(value)
          }
        }

        // console.log(finalTableData)
        x.push(finalTableData)
      }
      if (
        feature.properties?.messageType &&
        feature?.properties?.messageType == 'ALERT'
      ) {
        alertdata.push(feature)
      }
    }
    setAlertData(removeDuplicates(alertdata))
    setfinalTableDatas(x)
  }, [])

  useEffect(() => {
    if (alertData.length == 1) {
      setrenderSingleAlert(true)
    }
    if (finalTableDatas.length == 1) {
      setrenderSingleAirSig(true)
    }
  }, [alertData, finalTableDatas])
  if (renderSingleAlert && !renderSingleAirSig) {
    return <AlertBox properties={feature.properties} />
  }
  if (renderSingleAirSig && !renderSingleAlert) {
    return <MapPopupTable properties={feature.properties} />
  }

  return (
    <>
      {renderSingleAirSig ? (
        <MapPopupTable properties={feature.properties} />
      ) : renderSingleAlert ? (
        <AlertBox properties={feature.properties} />
      ) : (
        <div className="bg-white shadow-md rounded my-6">
          {finalTableDatas.map((finalTableData, i) => (
            <>
              <>
                <br></br>
                <button
                  onClick={() => activeDropdownHandler(i)}
                  className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                >
                  <div className="flex flex-row h-4">
                    <div
                      className="w-1/2"
                      style={{
                        backgroundColor: getAirSigColor(finalTableData),
                      }}
                    ></div>
                    <div className="w-1/2">
                      {finalTableData.find((item) => item.key === 'data').value}
                    </div>
                  </div>
                </button>
              </>
              {activeDropdown == i && (
                <table className="w-full table-auto leading-normal ">
                  <tbody>
                    <thead>
                      <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal w-auto">
                          Property
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-gray-600 text-left text-sm uppercase font-normal w-auto">
                          Value
                        </th>
                      </tr>
                    </thead>
                    {activeDropdown === i &&
                      finalTableData.map(
                        (item, index) =>
                          item.value != undefined &&
                          item.value != '' &&
                          item.value !== 'N/A' && (
                            <tr key={index}>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                {item.key}
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                {item.value} {item.units || ''}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              )}
            </>
          ))}

          {alertData.map((feature, index) => (
            <>
              <button
                onClick={() => selectAlert(index)}
                className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
              >
                Alert
                <div
                  style={{
                    backgroundColor: markerColorAlert(feature),
                    width: '10px',
                    height: '10px',
                    display: 'inline-block',
                    marginLeft: '5px',
                  }}
                ></div>
              </button>
              {selectedAlert == index && (
                <div onClick={() => handlePopupClick(feature.properties)}>
                  {true ? <AlertBox properties={feature.properties} /> : <></>}
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </>
  )
}

export function getAirSigColor(data) {
  const hazard = data.find((item) => item.key === 'hazard').value
  let polyonColor = 'black'
  if (hazard == 'CONVECTIVE') {
    polyonColor = 'Red'
  } else if (hazard == 'TURB') {
    polyonColor = 'Yellow'
  } else if (hazard == 'ICE') {
    polyonColor = 'Blue'
  } else if (hazard == 'IFR') {
    polyonColor = 'LightGreen'
  } else if (hazard == 'MTN OBSCN') {
    polyonColor = 'Green'
  }
  return polyonColor
}
function getOverlaps(feature, networkData) {
  let overlaps: any[] = []
  for (const i in networkData) {
    const data = networkData[i]
    if (
      (feature.properties?.airSigmetType == 'SIGMET' ||
        feature.properties?.data == 'AIRMET' ||
        (feature.properties?.messageType &&
          feature?.properties?.messageType == 'ALERT')) &&
      ((data.properties?.rawAirSigmet &&
        data.properties?.rawAirSigmet != feature.properties?.rawAirSigmet) ||
        (data.properties?.triggerEvent &&
          data.properties?.triggerEvent == feature.properties?.triggerEvent))
    ) {
      if (inside(feature.coordinates, data.coordinates)) {
        overlaps.push(data)
        networkData.splice(i, 1)
        overlaps = overlaps.concat(getOverlaps(data, networkData))
      }
    }
  }
  return overlaps
}

type Point = [number, number]
function inside(polygon1: Point[], polygon2: Point[]) {
  const result = intersection([polygon1], [polygon2])
  if (result.length === 0) {
    return false
  } else {
    return true
  }
}
function isEqual(obj1: any, obj2: any): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

// Function to remove duplicate objects from an array
function removeDuplicates(arr: any[]): any[] {
  return arr.filter((item, index, self) => {
    // Use the helper function to check for equality
    return index === self.findIndex((i) => isEqual(i, item))
  })
}

function markerColorAlert(feature: NetworkData) {
  let color = '#'
  elements.forEach((element) => {
    if (feature?.properties?.hazardType === element?.value) {
      color += element?.color
      return
    }
  })
  return color
}

export default PolygonPopupTable
