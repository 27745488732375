import './legend.css'
import { convertValuesToTruweatherUnits } from '../../../utils/WetDogWeatherUtils'
//
//  Takes in the colorMap and units passed form Legend.tsx, then generates the legend based on that information.
//
function LegendContent({ colorMap, units }) {
  let legend: HTMLElement[] = []
  if (colorMap) {
    const colors = colorMap.colors
    const values = colorMap.values
    const visibles = colorMap.visibles
    for (let i = 0; i < colors.length; i++) {
      const color = colors[i]
      let value = values[i]
      const visible = visibles[i]
      if (visible) {
        //could not think of a better name for this function.
        //takes in the value and units and spits out the value converted to the units
        // that we use in the workbook.
        value = convertValuesToTruweatherUnits(value, units)
        const newLegendBox = (
          <div className="legend-background" key={'legend-background-' + i}>
            <p
              className="legend-box"
              key={'legend-box-' + i}
              style={{ backgroundColor: color.str }}
            >
              {' '}
              {value.toFixed()} {units}
            </p>
          </div>
        )
        legend = [legend, newLegendBox]
      }
    }
    return <>{legend}</>
  }
}

export default LegendContent
