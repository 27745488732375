import {
  User,
  LoginPayload,
  RegisterPayload,
  UpdateProfilePayload,
} from '../interfaces/user'
import { TWS_API_URL } from '../environment/apis.config'
import useErrorStatus from '../hooks/UseErrorStatus'

export const fetchUserDetails = (): Promise<User> => {
  return new Promise((resolve, reject) => {
    // const token = sessionStorage.getItem('token')
    const token = localStorage.getItem('token')
    const url = `${TWS_API_URL}/client/me`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json().then((data) => {
            resolve(data) // Resolve with the JSON data
          })
        }else if(response.status== 403 || response.status== 400){
          let res = await response.json()
          if(res.message=="The token has expired!" || res.message == "Invalid Token!"){
            localStorage.removeItem('token')
            localStorage.removeItem('defaultLocation')
            localStorage.removeItem('userId')
            localStorage.setItem('logout-event', 'logout'+Math.random())
            window.location.reload()
          }
        } else {
          reject(new Error('Request failed')) // Reject on non-ok response
        }
      })
      .catch((error) => reject(error))
  })
}

export interface ISubscriptionInfo {
  productName: string | null
  price: number | null
  period: string | null
  date: string | null
  isActive: boolean | null
  cancelAt: string | null
  cancel_at_period_end: boolean | null
  trial_active: boolean | null
}

export const cancelSubscription = (): Promise => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/client/cancelSubscription`
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        clientId: localStorage.getItem('userId'),
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          resolve(data)
        })
      } else {
        reject(new Error('Request failed'))
      }
    })
  })
}

export const resubscribe = (): Promise => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/resubscribe/${localStorage.getItem('userId')}`
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        clientId: localStorage.getItem('userId'),
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          resolve(data)
        })
      } else {
        reject(new Error('Request failed'))
      }
    })
  })
}

export const subscriptionInfo = (): Promise<ISubscriptionInfo> => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/client/planinfo/`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ clientId: localStorage.getItem('userId') }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          resolve(data)
        })
      } else {
        reject(new Error('Request failed'))
      }
    })
    // })
  })
}

export const Login = (payload: LoginPayload): Promise<User> => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/auth/client/login`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error))
  })
}

export const UpdatePassword = (payload) => {
  // const errorStatus = useErrorStatus()
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/client/update-password`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          reject(new Error('Request failed'))
        } else resolve(response.json())
      })
      .catch((error) => {})
  })
}

export const UpdateProfile = (payload: UpdateProfilePayload) => {
  return new Promise((resolve, reject) => {
    const url = `${TWS_API_URL}/customUserProfile`
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error))
  })
}

const verifyCoupon = (coupon: string, email: string): Promise<boolean> => {
  const trialCoupon = coupon;
  return fetch(`${TWS_API_URL}/verifytrialcoupon`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      email: email,
      coupon: coupon,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data.verified
    }) // Assuming the response has an `isValid` field
    .catch(() => false); // If there's an error, assume the coupon is invalid
};

export const Register = async (payload: RegisterPayload) => {
  // return new Promise((resolve, reject) => {
  //   let url = `${TWS_API_URL}/auth/client/register`
  //   if (payload.coupon && payload.coupon != '') {
  //     url = `${TWS_API_URL}/auth/client/registerSpecialUser`
  //   }

  //   fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(payload),
  //   })
  //     .then(async (response) => {
  //       if (response.status === 200) {
  //         resolve(response.json())
  //       } else {
  //         const res = await response.json()
  //         reject(res)
  //       }
  //     })
  //     .catch((error) => {
  //       reject(error)
  //     })
  // })
  try {
    let url = `${TWS_API_URL}/auth/client/register`;
    let isCouponValid = false
    if (payload.coupon && payload.coupon !== '') {
      isCouponValid = await verifyCoupon(payload.coupon, payload.email);
      if (!isCouponValid) {
        url = `${TWS_API_URL}/auth/client/registerSpecialUser`;
      }
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      let res = await response.json();
      res["verifiedTrialCoupon"] = isCouponValid
      return res;
    } else {
      const res = await response.json();
      throw res;
    }
  } catch (error) {
    throw error;
  }
}

export const UpdateContact = async (
  userId: string,
  firstname: string,
  lastname: string,
  primary: string
) => {
  const updateContactDetails = {
    user: {
      firstname: firstname,
      lastname: lastname,
      // "email":email,
      primary: primary,
    },
  }

  const url = `${TWS_API_URL}/client/settings`

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        // email: email,
        primary: primary,
      }),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    return data
  } catch (error) {
    throw new Error('Error fetching user thresholds')
  }
}
