const buttonStyles = {
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  borderRadius: '0.25rem',
  width: '100%',
  fontWeight: 700,
  color: '#ffffff',
  backgroundColor: '#10B981',
}

const disabledButtonStyles = {
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  borderRadius: '0.25rem',
  width: '100%',
  fontWeight: 700,
  color: '#ffffff',
  backgroundColor: '#808080',
}

const inputFieldStyles = {
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  borderRadius: '0.25rem',
  borderWidth: '1px',
  width: '100%',
  lineHeight: 1.25,
  color: '#374151',
}

const labelStyles = {
  display: 'block',
  marginBottom: '0.5rem',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 700,
  color: '#374151',
}

const popupContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '20em',
  height: 'auto',
}

const inputFieldContainerStyles = {
  marginBottom: '1rem',
  shrink: '1',
  paddingLeft: '0.75rem',
}

const buttonContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '1em',
}

export {
  inputFieldStyles,
  labelStyles,
  popupContainerStyles,
  inputFieldContainerStyles,
  buttonContainerStyles,
  buttonStyles,
  disabledButtonStyles
}
