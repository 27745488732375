import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TWSLogo from '../../../assets/TruWeatherWhite.svg'
import Radar from '../../../assets/radar.gif'
import { useForm } from 'react-hook-form'
import { TWS_API_URL } from '../../../environment/apis.config'
import { RegisterUser } from '../UserSlice'
import { RegisterPayload, User } from '../../../interfaces/user'
import { ContactUsButton } from '../../ContactUs/ContactUs'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import Notifier from '../../Notifier/Notifier'

export default function RegisterPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onSubmit',
  })

  const [searchParams] = useSearchParams()
  const trialCoupon = searchParams.get('promo_code')
  const emailParam = searchParams.get('email')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorStatus = useErrorStatus()
  const [isEmailFromQuery, setIsEmailFromQuery] = useState(false)
  const [freetrial, setFreeTrial] = useState(false)

  useEffect(() => {
    if (trialCoupon) {
      setValue('coupon', trialCoupon)
    }
    if (emailParam) {
      setValue('email', emailParam)
      setIsEmailFromQuery(true)
    }
  }, [trialCoupon, emailParam, setValue])

  const onSubmit = async (data) => {
    const address = `${data.address}, ${data.city}, ${data.state} ${data.zip}`
    const userPayload: RegisterPayload = {
      isAdmin: false,
      freetrial,
      address,
      ...data,
    }
    userPayload.address = address
    const user = await dispatch(RegisterUser(userPayload))

    if (user?.payload?.token != undefined) {
      const userData: User = {
        ...user.payload,
        isAuthenticated: true,
        address: address,
      }
      localStorage.setItem('token', user.payload.token)
      localStorage.setItem('userId', user.payload.id)
      if (
        !freetrial &&
        (user.payload.reseller === null ||
          user.payload.reseller.reseller === 'default')
      ) {
        const url = `${TWS_API_URL}/payment?token=${userData.token}`
        window.location.href = url
      } else {
        navigate('/map')
      }
    } else if (user?.error?.message) {
      errorStatus.addMessage(
        user?.error?.message || 'Something went wrong',
        400,
        'error',
        true
      )
    }
  }
  const onError = () => {
    errorStatus.addMessage(
      'Please check the Form for errors and try again',
      400,
      'error',
      true
    )
  }

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      navigate('/map')
    }
  })

  return (
    <>
      <Notifier position={'top-right'} />
      <div className="flex flex-col h-full absolute w-full left-0 right-0">
        <div className="grow bg-white flex flex-col items-center justify-center bg-white sm:flex-row">
          <div className="px-16 bg-white">
            <div className="flex flex-col items-center">
              <img className="p-8 max-w-[512px]" src={Radar} />
              <img className="w-65 h-28" src={TWSLogo} />
            </div>
          </div>
          <div className="bg-white px-4">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div>
                <h2 className="text-center text-2xl font-bold mb-2 tracking-wider">
                  Create an Account
                </h2>
              </div>
              <div className="flex items-center justify-center gap-2 content-stretch">
                <div className="flex flex-col w-24 gap-2 grow-0 shrink-0">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="title"
                    type="text"
                    placeholder="Mr."
                    {...register('title')}
                  />
                </div>
                <div className="flex flex-col gap-2 grow relative">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="firstName"
                  >
                    First Name{' '}
                    <span className="text-[#ff0000] font-bold">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${
                      errors.firstname ? 'border-red-500' : 'rounded'
                    } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="firstName"
                    type="text"
                    placeholder="Anakin"
                    {...register('firstname', {
                      required: 'First Name is required',
                    })}
                    title={errors.firstname ? errors.firstname.message : ''}
                  />
                  {errors.firstname && (
                    <svg
                      className="absolute right-3 top-[2.8rem] h-5 w-5 text-red-500" // Tailwind class for size, adjust as needed
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {errors.firstname && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.firstname.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2 grow relative">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="lastName"
                  >
                    Last Name{' '}
                    <span className="text-[#ff0000] font-bold">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${
                      errors.lastname ? 'border-red-500' : 'rounded'
                    } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="lastName"
                    type="text"
                    placeholder="Skywalker"
                    {...register('lastname', {
                      required: 'Last Name is required',
                    })}
                    title={errors.lastname ? errors.lastname.message : ''}
                  />
                  {errors.lastname && (
                    <svg
                      className="absolute right-3 top-[2.8rem] h-5 w-5 text-red-500" // Tailwind class for size, adjust as needed
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {/* Optionally, adjust the visibility or styling of the inline error message. */}
                  {errors.lastname && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.lastname.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center relative">
                <div className="flex flex-col gap-2 grow">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="companyName"
                  >
                    Company Name{' '}
                    <span className="text-[#ff0000] font-bold">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${
                      errors.company ? 'border-red-500' : 'rounded'
                    } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="companyName"
                    type="text"
                    placeholder="ACME Corporation"
                    {...register('company', {
                      required: 'Company is required',
                    })}
                    title={errors.company ? errors.company.message : ''}
                  />
                  {errors.company && (
                    <svg
                      className="absolute right-3 top-[2.8rem] h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                  {errors.company && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.company.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="flex flex-col gap-2 grow">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="streetAddress"
                  >
                    Street Address
                  </label>
                  <input
                    className="border border-gray-300 rounded p-1 text-gray-700"
                    type="text"
                    id="streetAddress"
                    placeholder="1640 Riverside Drive"
                    {...register('address')}
                  />
                </div>
                <div className="flex flex-col w-28 gap-2">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="city"
                  >
                    City
                  </label>
                  <input
                    className="border border-gray-300 rounded p-1 text-gray-700"
                    type="text"
                    id="city"
                    placeholder="Hill Valley"
                    {...register('city')}
                  />
                </div>
                <div className="flex flex-col w-16 gap-2">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="state"
                  >
                    State
                  </label>
                  <input
                    className="border border-gray-300 rounded p-1 text-gray-700"
                    type="text"
                    placeholder="CA"
                    id="state"
                    {...register('state')}
                  />
                </div>
                <div className="flex flex-col w-20 gap-2">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="postCode"
                  >
                    Zip Code
                  </label>
                  <input
                    className="border border-gray-300 rounded p-1 text-gray-700"
                    type="text"
                    id="postCode"
                    placeholder="12345"
                    {...register('zip')}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div className="flex flex-col gap-2 grow relative">
                  <label className="block text-gray-700 text-md font-bold mb-1 tracking-wide">
                    Primary Phone{' '}
                    <span className="text-[#ff0000] font-bold">*</span>
                  </label>
                  <input
                    className="border border-gray-300 p-1 text-gray-700"
                    type="text"
                    placeholder="(123) 456-7890"
                    {...register('primary', {
                      required: 'Phone number is required',
                    })}
                    title={errors.primary ? errors.primary.message : ''}
                  />
                  {errors.primary && (
                    <svg
                      className="absolute right-3 top-[2.8rem] h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                  {errors.primary && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.primary.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2 grow">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="altPhoneNumber"
                  >
                    Secondary Phone
                  </label>
                  <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="altPhoneNumber"
                    type="text"
                    placeholder="(123) 456-7890"
                    {...register('secondary')}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col gap-2 grow relative">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="email"
                  >
                    Email <span className="text-[#ff0000] font-bold">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${
                      errors.email ? 'border-red-500' : 'rounded'
                    } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="email"
                    type="email"
                    name="email"
                    disabled={isEmailFromQuery}
                    placeholder="test@test.com"
                    {...register('email', {
                      required: 'Email address is required',
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email address',
                      },
                    })}
                    title={errors.email ? errors.email.message : ''}
                  />
                  {errors.email && (
                    <svg
                      className="absolute right-3 top-[2.8rem] h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                  {errors.email && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col gap-2 grow relative">
                  <label
                    className="block text-gray-700 text-md font-bold mb-1 tracking-wide"
                    htmlFor="password"
                  >
                    Password<span className="text-[#ff0000] font-bold">*</span>
                  </label>
                  <input
                    className={`appearance-none border ${
                      errors.password ? 'border-red-500' : 'rounded'
                    } w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    id="password"
                    type="password"
                    placeholder="abcd@123"
                    {...register('password', {
                      required: 'Password is required',
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          'Password must contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one special character, and one number',
                      },
                    })}
                    title={errors.password ? errors.password.message : ''}
                  />
                  {errors.password && (
                    <svg
                      className="absolute right-3 top-[2.8rem] h-5 w-5 text-red-500" // Adjust the top value as needed for alignment
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="16" cy="16" r="16" className="fill-red-600" />
                      <path
                        d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
                        className="fill-gray-200"
                      />
                    </svg>
                  )}
                  {/* Optionally, you can adjust the visibility or styling of the inline error message. */}
                  {errors.password && (
                    <p className="text-red-500 before:content-['⚠'] hidden">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col gap-2 grow">
                  <label className="block text-gray-700 text-md font-bold mb-1 tracking-wide">
                    Coupon
                  </label>
                  <input
                    className="border border-gray-300 rounded p-1 text-gray-700"
                    type="text"
                    name="coupon"
                    placeholder=""
                    {...register('coupon')}
                  />
                </div>
              </div>
              <div className="flex items-center justify-start gap-2 mt-2">
                <input
                  id="link-checkbox"
                  type="checkbox"
                  className={`w-4 h-4  bg-blue-100  ${
                    errors.termsAndConditions ? 'ring-2 ring-red-500' : ''
                  }`}
                  {...register('termsAndConditions', {
                    required: 'Selecting Terms and Conditions is required',
                  })}
                />
                <label className="ml-2  font-medium text-gray-900 dark:text-gray-300">
                  By signing up, you agree to our{' '}
                  <a
                    href="https://truflitev360.com/assets/t&c.pdf"
                    target="_blank"
                    className="text-blue-600 dark:text-blue-500 hover:underline"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>{' '}
                  policy. <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="flex items-center justify-start gap-2 mt-2">
                <input
                  id="free-trial"
                  type="checkbox"
                  className="w-4 h-4  bg-blue-100"
                  checked={freetrial}
                  onChange={() => setFreeTrial(!freetrial)}
                />
                <label
                  htmlFor="free-trial"
                  className="ml-2  font-medium text-gray-900 dark:text-gray-300"
                >
                  Sign up for a free trail (No credit card required)
                </label>
              </div>
              <div className="flex items-center justify-center">
                <div className="basis-auto grow py-4">
                  <button className="bg-green-600 rounded text-white w-full uppercase py-2 px-12 font-bold">
                    Register
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-end gap-2 mt-2">
                <Link to="/login">
                  <span className="text-[#1E88E5]">Sign in instead.</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
        <div className="grow-0 flex justify-center h-12">
          <div className="flex items-center mx-4">
            <a
              href="https://truweathersolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black tracking-wider"
            >
              TruWeather Solutions
            </a>
            <img
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik03IDE3TDE3IDdNMTcgN0g4TTE3IDdWMTYiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg=="
              width="20"
              height="20"
            />
          </div>
          <div className="flex items-center mx-4">
            <a
              href="https://truflitev360.com/assets/t&amp;c.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black tracking-wider"
            >
              Terms of Use
            </a>
            <img
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik03IDE3TDE3IDdNMTcgN0g4TTE3IDdWMTYiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg=="
              width="20"
              height="20"
            />
          </div>
        </div>
      </div>
      <ContactUsButton></ContactUsButton>
    </>
  )
}
